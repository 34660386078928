import { defineStore } from 'pinia';

export const usePageLoaderStore = defineStore('page-loader-store', {
    state: () => {
        return {
            isShow: false,
        }
    },

    getters:{
        getIsShow: state => state.isShow,
    },

    actions:{
        showPageLoader(isShow) {
            this.isShow = isShow;
        }
    },
})
